@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "thongterm", sans-serif;
  font-weight: 400;
  background-color: #f8f8f8;
}

h1,
h2,
h3,
h4 {
  font-family: "termtem", sans-serif;
  font-weight: 700;
}

p,
h5,
h6 {
  font-size: 16px;
}

.prose {
  font-size: 16px;
}
.prose h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.5;
  margin: 12px 0;
}
.prose h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.5;
  margin: 12px 0;
}
.prose h3 {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.5;
  margin: 12px 0;
}
.prose h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  margin: 12px 0;
}

.prose h5,
.prose h6,
.prose p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.65;
  margin: 10px 0;
}

.prose li {
  margin: 4px 0;
}

.prose hr {
  margin: 22px 0;
}

.prose img {
  max-width: 65%;
  margin: 22px auto;
}

@media only screen and (max-width: 1280px) {
  .prose img {
    max-width: 100%;
  }
}
